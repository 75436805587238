<template>
    <div>
        <h2>จัดการลิ้งค์ตัวแทน</h2>
        <v-card class="mt-4">
          <div class="d-flex pa-4">
            <v-btn              
              @click="deleteMultiple()"
              :disabled="datatable.selectedItems.length == 0"
            ><v-icon>{{ mdiDeleteOutline }}</v-icon></v-btn>                    
            <v-btn color="primary" class="ml-auto" @click="$router.push({ 'name': 'shareablelink_create', params: { shareablelink_id: 'create' } })">เพิ่มลิ้งตัวแทน</v-btn>
          </div>
          <delay-datatable
            show-select
            :headers="datatable.headers"
            :url="'/inventory/shareablelink/list'"            
            :queryParams="getDatatableQueryParams"
            :options.sync="datatable.options"
            :isSaveState="true"
            @loadState="loadDatatableState"
            v-model="datatable.selectedItems"
            ref="mainDatatable"
          >

          <template v-slot:[`header.data-table-select`]="{ props, on }">
            <v-simple-checkbox
              :value="props.value || props.indeterminate"
              v-on="on"
              :indeterminate="props.indeterminate"
              color="primary"
              :ripple="false"
            />
          </template>

          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">            
            <v-simple-checkbox
              v-model="isSelected"
              color="primary"
              @input="select"
              :ripple="false"
            />
          </template>

          <template          
            v-slot:item.warehouse="{ item }"
          >
            {{ !!item.warehouse ? item.warehouse.name : '-' }}
          </template>

          <template          
            v-slot:item.brands="{ item }"
          >
            {{ !!item.brands ? item.brands.map(item => item.name).join(', ') : '-' }}
          </template>

          <template          
            v-slot:item.actions="{ item }"
          >   
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>{{ mdiMenu }}</v-icon>                  
                </v-btn>
              </template>
              <v-list>                
                <v-list-item @click="showLinkBox(item.uuid)">
                  <v-list-item-icon>
                    <v-icon>{{mdiLinkVariant}}</v-icon>
                  </v-list-item-icon>                  
                  <v-list-item-title class="ml-2">ขอลิ้งค์</v-list-item-title>
                </v-list-item>         

                <v-divider></v-divider>

                <v-list-item @click="$router.push({name: 'shareablelink_create', params: { shareablelink_id: item.id }})">                  
                  <v-list-item-icon>
                    <v-icon>
                      {{ $store.getters['auth/GET_ABILITY'].can('update', 'Inventory') ? mdiPlaylistEdit : mdiEyeOutline }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="ml-2">ดู / แก้ไข</v-list-item-title>
                </v-list-item>


                <template v-if="$store.getters['auth/GET_ABILITY'].can('update', 'Inventory')">
                  <v-divider></v-divider>
                
                  <v-list-item @click="deleteSingle(item.id, item.name)">                  
                    <v-list-item-icon>
                      <v-icon>
                      {{ mdiDelete }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">ยกเลิก</v-list-item-title>
                  </v-list-item>
                </template>                

              </v-list>
            </v-menu>            
          </template>
          </delay-datatable>
        </v-card>        

        <v-dialog v-model="linkReqDialog.isShow" max-width="600">
          <v-card>
            <v-card-title>ลิ้งเชิญ</v-card-title>
            <div class="px-4">
              <copy-box
                dense
                outlined
                :value="linkReqDialog.link"
              ></copy-box>
            </div>
            <v-card-actions class="justify-end">
              <v-btn @click="linkReqDialog.isShow = false" color="primary">ปิด</v-btn>
            </v-card-actions>    
          </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { asyncDelete } from '@/helpers/asyncAxios'
import { convertTimestampToAooDefault } from '@/helpers/converter'
import { mdiDelete, mdiDeleteOutline, mdiEyeOutline, mdiLinkVariant, mdiMenu, mdiPlaylistEdit } from '@mdi/js'
export default {
  data() {
    return {
      linkReqDialog: {
        isShow: false,
        link: null,
      },
      datatable: {
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        selectedItems: [],
        search: null,
        headers: [
          {
            text: 'uuid',
            value: 'uuid',
          },
          {
            text: 'ชื่อลิ้งค์',
            value: 'name',
          },
          {
            text: 'คลัง',
            value: 'warehouse',
            sortable: false,
          },
          {
            text: 'แบรนด์',
            value: 'brands',
            sortable: false,
          },
          {
            text: '#',
            value: 'actions',
            sortable: false,
            align: 'center',
          },
        ],
      },
      mdiDeleteOutline,
      mdiPlaylistEdit,
      mdiEyeOutline,
      mdiLinkVariant,
      mdiMenu,
      mdiDelete,
      converters: {
        convertTimestampToAooDefault,
      },
    }
  },
  computed: {
    getDatatableQueryParams() {
      return {
        search: this.datatable.search,
      }
    },
  },
  mounted() {
    if (!this.$route.query.uuid) return

    this.showLinkBox(this.$route.query.uuid)
  },
  methods: {
    showLinkBox(uuid) {
      this.linkReqDialog.link = process.env.VUE_APP_SHAREDLINK_URL + '/' + uuid
      this.linkReqDialog.isShow = true
    },
    loadDatatableState({ options, queryParams }) {
      this.datatable = { ...this.datatable, options: { ...options }, ...queryParams }
    },
    isArray(data) {
      return Array.isArray(data)
    },
    async deleteMultiple() {
      const shareablelinkNames = this.datatable.selectedItems.map(item => item.name).join(', ')
      const shareablelinkIds = this.datatable.selectedItems.map(item => item.id)

      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยืนยันที่จะลบ ?',
        body:
          'คุณต้องการที่จะลบ "' + shareablelinkNames + '" หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถกู้ข้อมูลกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      try {
        const result = await asyncDelete('/inventory/shareablelink', { items: shareablelinkIds })
        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
    async deleteSingle(id, name) {
      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยืนยันที่จะลบ ?',
        body: 'คุณต้องการที่จะลบ "' + name + '" หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถกู้ข้อมูลกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      try {
        const result = await asyncDelete('/inventory/shareablelink', { items: [id] })
        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
  },
}
</script>